import React from 'react'
import "./apartmentinfo.css"

import GrassIcon from '@mui/icons-material/Grass';

function ApartmentInfo() {
  return (
    <div className='ai'>
      <h1>OKOLICA OBJEKTA</h1>
      <div className='ai-box'>
        <h2>ŠTO JE U BLIZINI</h2>
        <ul>
          <li><GrassIcon className='graas'/> Arheološki park Salona (1.2 km)</li>
          <li><GrassIcon className='graas'/> Mall of Split (1.6 km)</li>
          <li><GrassIcon className='graas'/> Trgovački centar City Center One Split (2.5 km)</li>
          <li><GrassIcon className='graas'/> Sports Hall of Fame Museum (3.0 km)</li>
          <li><GrassIcon className='graas'/> Joker (3.6 km)</li>
          <li><GrassIcon className='graas'/> Stadion Park mladeži (4.2 km)</li>
          <li><GrassIcon className='graas'/> Hrvatski pomorski muzej (4.3 km)</li>
          <li><GrassIcon className='graas'/> Sportska dvorana Spaladium Arena (4.5 km)</li>
          <li><GrassIcon className='graas'/> Live Museum Split (4.6 km)</li>
          <li><GrassIcon className='graas'/> Church of St. Martin (4.7 km)</li>
        </ul>
      </div>

      <div className='ai-box'>
        <h2>RESTORANI I KAFIĆI</h2>
        <ul>
          <li><GrassIcon className='graas'/> Kafić CAPPUCCIO Bar (0.1 km)</li>
          <li><GrassIcon className='graas'/> Restoran PIZZERIA KONOBA GIRASOLE (0.5 km)</li>
          <li><GrassIcon className='graas'/> Restoran Restourant hotel President (0.5 km)</li>
          <li><GrassIcon className='graas'/> Kafić Dvije palme (0.8 km)</li>
        </ul>
      </div>

      <div className='ai-box'>
        <h2>PLAŽE U OKOLICI</h2>
        <ul>
          <li><GrassIcon className='graas'/> Duilovo Beach (3.5 km)</li>
          <li><GrassIcon className='graas'/> Znjan Beach (3.5 km)</li>
          <li><GrassIcon className='graas'/> Duilovo Dog Beach (3.5 km)</li>
          <li><GrassIcon className='graas'/> Trstenik (3.9 km)</li>
          <li><GrassIcon className='graas'/> Plaža Stobreč — Jug (4.2 km)</li>
        </ul>
      </div>

      <div className='ai-box'>
        <h2>PRIRODNE LJEPOTE</h2>
        <ul>
          <li><GrassIcon className='graas'/> Rijeka Jadro (0.1 km)</li>
          <li><GrassIcon className='graas'/> Jadransko more (1.7 km)</li>
          <li><GrassIcon className='graas'/> Planina Kozjak (2.0 km)</li>
          <li><GrassIcon className='graas'/> Planina Mosor (4.0 km)</li>
          <li><GrassIcon className='graas'/> Jezero Peruča (30 km)</li>
          <li><GrassIcon className='graas'/> Rijeka Cetina (30 km)</li>
        </ul>
      </div>

      <div className='ai-box'>
        <h2>NAJPOPULARNIJE ATRAKCIJE</h2>
        <ul>
          <li><GrassIcon className='graas'/> Grgur Ninski (4.7 km)</li>
          <li><GrassIcon className='graas'/> Muzej grada Splita (4.7 km)</li>
          <li><GrassIcon className='graas'/> Arheološki muzej u Splitu (4.7 km)</li>
          <li><GrassIcon className='graas'/> Dioklecijanova palača (4.8 km)</li>
          <li><GrassIcon className='graas'/> Plaža Bačvice (4.8 km)</li>
          <li><GrassIcon className='graas'/> Plaža Ježinac (6.6 km)</li>
          <li><GrassIcon className='graas'/> Plaža Kaštelet (6.8 km)</li>
          <li><GrassIcon className='graas'/> Plaža Bene (7.3 km)</li>
          <li><GrassIcon className='graas'/> Plaža Kašjuni (7.7 km)</li>
        </ul>
      </div>

      <div className='ai-box'>
        <h2>NAJBLIŽE ZRAČNE LUKE</h2>
        <ul>
          <li><GrassIcon className='graas'/> Zračna luka Split (15.3 km)</li>
        </ul>
      </div>

      <div className='ai-box'>
        <h2>PRIJEVOZ OD APARTMANA DO ZRAČNE LUKE SPLIT</h2>
        <ul>
          <li><GrassIcon className='graas'/> Taksijem</li>
        </ul>
      </div>
    </div>
  )
}

export default ApartmentInfo