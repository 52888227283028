import React from 'react'
import "./reviews.css"

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ForestIcon from '@mui/icons-material/Forest';
import SocialDistanceIcon from '@mui/icons-material/SocialDistance';
import FastfoodIcon from '@mui/icons-material/Fastfood';
import SanitizerIcon from '@mui/icons-material/Sanitizer';
import SecurityIcon from '@mui/icons-material/Security';
import StarsIcon from '@mui/icons-material/Stars';

import imgx from "../../files/img17.jpeg"
import imgy from "../../files/img23.jpeg"
import imgz from "../../files/img27.jpeg"
import imgw from "../../files/img31.jpeg"

import rev1 from "../../files/rev1.png"
import rev2 from "../../files/rev2.png"
import rev3 from "../../files/rev3.png"


import { Link } from 'react-router-dom';


function Reviews() {
  return (
    <div className='rv'>
      <div className='rvs'>
      <div className='rv1'>
        <h1>Domaćini (fizičke osobe)</h1>
        <p>Domaćini koji su se registrirali na Booking.com-u kao fizičke osobe strane su koje iznajmljuju svoj objekt ili objekte, a da to nije njihova struka ili profesija. Oni nisu profesionalni iznajmljivači (kao recimo globalni hotelski lanci) i stoga se na njih možda ne odnose isti zakoni EU-a o zaštiti potrošača. Ali budite bez brige, Booking.com vam pruža istu korisničku podršku za svaki boravak. To ne znači da će se vaš boravak ili iskustvo razlikovati od rezervacije smještaja kod profesionalnog iznajmljivača.</p>
      </div>
      <div className='rv2'>
        <h1>Nadoknađujemo razliku u cijeni</h1>
        <p>Možete zatražiti nadoknadu razlike u cijeni ako objekt koji ste rezervirali pronađete na nekoj drugoj stranici po nižoj cijeni. Samo nas kontaktirajte nakon što rezervirate putem naše stranice, a najkasnije 24 sata prije prijave u objekt. Potreban nam je link na drugu ponudu, koja u trenutku naše provjere mora biti dostupna online i raspoloživa. Ispod je dostupno i više uputa.</p>
      </div>
      <div className='rv3'>
        <h1>Istaknute prednosti objekta</h1>
        <p>Apartmani sadrže namještenu terasu ili balkon, TV ravnog ekrana s kabelskim programima i prostor za sjedenje s kaučom. Također imaju potpuno opremljenu kuhinju s pećnicom, hladnjakom, perilicom posuđa i blagovaonicom. Vlastita kupaonica sadrži tuš, perilicu rublja, sušilo za kosu i besplatan kozmetički pribor.</p>
      </div>
      </div>

      <div className='more'>
        <h1>Nadoknađujemo razliku u cijeni</h1>
        <h2>Možete zatražiti nadoknadu razlike u cijeni ako objekt koji ste rezervirali pronađete na nekoj drugoj stranici po nižoj cijeni.</h2>
        <p>Samo nas kontaktirajte nakon što rezervirate putem naše stranice, a najkasnije 24 sata prije prijave u objekt. Potreban nam je link na drugu ponudu, koja u trenutku naše provjere mora biti dostupna online i raspoloživa.</p>

        <div className='inner-more'>
          <div className='inner-more-x'>
            <h2>Uvjeti za nadoknadu razlike</h2>
            <ul>
              <li><CheckCircleIcon className='im-icon' /> Druga ponuda mora se odnositi na isti smještajni objekt i istu vrstu smještaja.</li>
              <li><CheckCircleIcon className='im-icon' /> Druga ponuda mora se odnositi na iste datume prijave i odjave.</li>
              <li><CheckCircleIcon className='im-icon' /> Druga ponuda mora imati ista pravila otkazivanja i uvjete.</li>
            </ul>
          </div>
          <div className='inner-more-x'>
            <h2>Kada nemate pravo na naknadu?</h2>
            <ul>
              <li><CheckCircleIcon className='im-icon' /> Ako se druga ponuda nalazi na stranici koja objekt ili vrstu objekta u kojem ćete boraviti otkriva tek nakon rezervacije.</li>
              <li><CheckCircleIcon className='im-icon' /> Ako je druga ponuda dio programa vjernosti ili nagrađivanja korisnika. Ovu vrstu druge ponude definiramo kao bilo koju situaciju u kojoj korisnik dobije nižu cijenu od objekta ili druge internetske stranice kao nagradu za ponovno poklanjanje vjernosti, prijavu u korisnički račun, unošenje promotivnog koda, preporučivanje drugih korisnika ili izvršavanje bilo koje druge radnje koja utječe na promjenu izvorno prikazane cijene.</li>
              <li><CheckCircleIcon className='im-icon' /> Ako vaša rezervacija ili ponuda s kojom vršite usporedbu ima oznaku 'Ponuda partnerske stranice' (odnosno ako je nudi partnerska tvrtka Booking.com-a).</li>
            </ul>
          </div>
         
        </div>
        <h3>Polog za slučaj štete u iznosu od EUR 200 potrebno je uplatiti prilikom dolaska. To je oko 1515.17 HRK. Ova će se naknada naplatiti u gotovini. Polog će vam biti vraćen prilikom odjave. Puni iznos vašeg pologa bit će u gotovini vraćen nakon pregleda smještajnog objekta.</h3>
        <div className='organise'>  
       <ForestIcon className='forest' />  <p>Ovaj je smještajni objekt poduzeo korake kako bi pružio održivija i ekološki prihvatljivija putovanja. Moguća je ponovna upotreba ručnika ili odustajanje od svakodnevnog čišćenja, na zahtjev samog korisnika.</p>
        </div>
      </div>

      <div className='qa'>
        <h1>Pitanja i odgovori</h1>

        <h2>Prihvaćate li kreditne kartice?</h2>
        <p>Da, moguće je plaćati kreditnim karticama.</p>
<h2>Je li moguć najam za rođendanske proslave i evente?</h2>
        <p>Ovisi o raspoloživosti termina i dogovoru sa samim vlasnikom. Najbolje se obratite upitom i provjerite izravno.</p>
        <h2>Imate li klima-uređaje?</h2>
        <p>Apartman Jasminka ima tri klima-uređaja, svaka soba svoju klimu i dnevni boravak svoju</p>
        <h2>Od kada ste na Booking.com?</h2>
        <p>Apartments Jasminka pruža dobrodošlicu preko Booking.com gostima od 20. lipnja 2014.</p>
        <h2>Posjedujete li parking?</h2>
        <p>U sklopu objekta dostupan je besplatni privatni parking.</p>
        <h2>Je li osiguran besplatan internet?</h2>
        <p>Da, možete koristiti besplatan wi-fi.</p>
        <h2>Je li moguće korištenje bazena?</h2>
        <p>Naravno. Ovaj objekt smješten je u tradicionalnoj dalmatinskoj kamenoj kući, a dio sadržaja je i sezonski vanjski bazen.</p>
      </div>






      <div className='ai'>
      <h1>ZDRAVLJE I SIGURNOST</h1>
      <div className='ai-box'>
        <h2>Sigurnosne značajke</h2>
        <ul>
          <li><SecurityIcon className='graas'/> Osoblje prati sve sigurnosne protokole lokalnih nadležnih vlasti</li>
          <li><SecurityIcon className='graas'/> Uklonjeni su predmeti za zajedničko korištenje kao što su isprintani jelovnici, časopisi, kemijske olovke i papir</li>
          <li><SecurityIcon className='graas'/> U smještaju za goste i glavnim prostorijama nalazi se sredstvo za dezinfekciju ruku</li>
          <li><SecurityIcon className='graas'/> Dostupna je kutija prve pomoći</li>
          <li><SecurityIcon className='graas'/> Dostupnost stručnog zdravstvenog osoblja</li>
       </ul>
      </div>

      <div className='ai-box'>
        <h2>Čistoća i dezinfekcija</h2>
        <ul>
          <li><SanitizerIcon className='graas'/> Korištenje sredstava za čišćenje učinkovitih protiv koronavirusa</li>
          <li><SanitizerIcon className='graas'/> Pranje posteljine, ručnika i rublja u skladu sa smjernicama lokalnih vlasti</li>
          <li><SanitizerIcon className='graas'/> Smještaj za goste dezinficira se između boravaka gostiju</li>
          <li><SanitizerIcon className='graas'/> Gosti mogu otkazati usluge čišćenja smještaja tijekom boravka</li>
        </ul>
      </div>

      <div className='ai-box'>
        <h2>Sigurnost hrane i pića</h2>
        <ul>
          <li><FastfoodIcon className='graas'/> Mogućnost dostave hrane u smještaj za goste</li>
          <li><FastfoodIcon className='graas'/> Svi su tanjuri, pribor za jelo i ostali pribor dezinficirani</li>
        </ul>
      </div>

      <div className='ai-box'>
        <h2>Fizičko distanciranje</h2>
        <ul>
          <li><SocialDistanceIcon className='graas'/> Beskontaktna prijava/odjava</li>
          <li><SocialDistanceIcon className='graas'/> Mogućnost plaćanja bez gotovine</li>
          <li><SocialDistanceIcon className='graas'/> Na snazi su mjere fizičkog distanciranja</li>
        </ul>
      </div>
    </div>

<div className='carousel'>  
<img src={imgx} alt="Izdvojeno iz galerije, prikaz interijera i eksterijera Apartmana Jasminka u Dalmaciji, grad Solin" className='imgx' draggable="false" />
<img src={imgy} alt="Izdvojeno iz galerije, prikaz interijera i eksterijera Apartmana Jasminka u Dalmaciji, grad Solin" className='imgx' draggable="false" />
<img src={imgz} alt="Izdvojeno iz galerije, prikaz interijera i eksterijera Apartmana Jasminka u Dalmaciji, grad Solin" className='imgx' draggable="false" />
<img src={imgw} alt="Izdvojeno iz galerije, prikaz interijera i eksterijera Apartmana Jasminka u Dalmaciji, grad Solin" className='imgx' draggable="false" />
</div>

<Link to="/galerija" className='rv-link'>Pogledajte cijelu galeriju</Link>


<h1 className='rv-label'>Kako su nas gosti ocijenili?</h1>
<h2 className='rv-stars'><StarsIcon className='stars' /><StarsIcon className='stars' /><StarsIcon className='stars' /><StarsIcon className='stars' /><StarsIcon className='stars' />8.7</h2>
<h3 className='rv-desc'>Na Booking.com naš apartman ostvario je izvanredne ocjene, a koje ispod može vidjeti prema kategorijama. Ove se ocjene prema Bookingu smatraju iznadprosječnima za grad Solin. Svoje recenzije i pozitivne ocjene našeg smještaja ostavilo je više od 30 osoba. Pozivamo vas da pogledate zašto se ljudi odlučuju za ovaj apartman!</h3>
<div className='rv-last'>
  <img src={rev1} alt="Recenzije Apartmana Jasminka iz Solina u Dalmaciji, izdvojeno iz Booking.com" draggable="false" />
  <img src={rev2} alt="Recenzije Apartmana Jasminka iz Solina u Dalmaciji, izdvojeno iz Booking.com" draggable="false" />
  <img src={rev3} alt="Recenzije Apartmana Jasminka iz Solina u Dalmaciji, izdvojeno iz Booking.com" draggable="false" />
</div>

<a href="https://www.booking.com/hotel/hr/apartman-jasminka.hr.html?account_created=1&activeTab=main#tab-reviews" className='rv-link' target="_blank" rel="noreferrer">Sve recenzije na Bookingu</a>



    </div>
  )
}

export default Reviews