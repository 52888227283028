import React from 'react'
import "./footer.css"
import { Link } from 'react-router-dom';

import WhatsAppIcon from '@mui/icons-material/WhatsApp';

function Footer() {

  let myDate = new Date()

let someDay = myDate.getDate();
let someMonth = myDate.getMonth() + 1;
let someYear = myDate.getFullYear();


  return (
    <div className='footer'>
      <div className='ft1'>
        <div className='row1'>
         <Link to="/" className='track-link-foot'>Početna</Link>
        <Link to="/sadrzaj" className='track-link-foot'>Sadržaj</Link>
        <Link to="/galerija" className='track-link-foot'>Galerija</Link>
        <Link to="/kucni-red" className='track-link-foot'>Kućni red</Link>
        <Link to="/kontakt-i-cijene" className='track-link-foot'>Kontakt</Link>
        </div>

        <div className='row2'>
        <a href='https://www.booking.com/hotel/hr/apartman-jasminka.hr.html?account_created=1&activeTab=main#availability' target="_blank" rel="noreferrer" className='track-link-foot-2'>Booking.com</a>
        <a href='mailto:petric206@gmail.com' target="_blank" rel="noreferrer" className='track-link-foot-2'>petric206@gmail.com</a>
        <a href='https://www.google.com/maps/place/Vladimira+%C4%8Cerine+9,+21210,+Solin/@43.5128674,16.4831765,12.72z/data=!4m5!3m4!1s0x13355fab2c6fe531:0x2a6393c33ff14726!8m2!3d43.5331832!4d16.4879404' target="_blank" rel="noreferrer" className='track-link-foot-2'>Vladimira Čerine 9, Solin</a>
        <a href='tel:+38598714051' target="_blank" rel="noreferrer" className='track-link-foot-2'>+385 98 714 051</a>
        <a href="https://api.whatsapp.com/send?phone=38598714051" target="_blank" rel="noreferrer" className='links2 icc'><WhatsAppIcon className='icc2'/></a>

        </div>
      </div>
      <div className='sign'>
          Sva prava pridržana ~ Izradio <a href="https://mathscript.eu" className="math" target="_blank" rel="noreferrer">Mathscript</a> ~ { `${someDay}. ${someMonth}. ${someYear}.` } 
      </div>
    </div>
  )
}

export default Footer