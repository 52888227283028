import React, {useEffect} from 'react'
import "./gallery.css"

import Header from "../aheader/Header"
import Footer from "../afooter/Footer"

import img365 from "../../files/img18.jpeg"

import img1 from "../../files/img1.jpeg"
import img2 from "../../files/img2.jpeg"
import img3 from "../../files/img3.jpeg"
import img4 from "../../files/img4.jpeg"
import img5 from "../../files/img5.jpeg"
import img6 from "../../files/img6.jpeg"
import img7 from "../../files/img7.jpeg"
import img8 from "../../files/img8.jpeg"
import img9 from "../../files/img9.jpeg"
import img10 from "../../files/img10.jpeg"
import img11 from "../../files/img11.jpeg"
import img12 from "../../files/img12.jpeg"
import img13 from "../../files/img13.jpeg"
import img14 from "../../files/img14.jpeg"
import img15 from "../../files/img15.jpeg"
import img16 from "../../files/img16.jpeg"
import img17 from "../../files/img17.jpeg"
import img18 from "../../files/img18.jpeg"
import img19 from "../../files/img19.jpeg"
import img20 from "../../files/img20.jpeg"
import img21 from "../../files/img21.jpeg"
import img22 from "../../files/img22.jpeg"
import img23 from "../../files/img23.jpeg"
import img24 from "../../files/img24.jpeg"
import img25 from "../../files/img25.jpeg"
import img26 from "../../files/img26.jpeg"
import img27 from "../../files/img27.jpeg"
import img28 from "../../files/img28.jpeg"
import img29 from "../../files/img29.jpeg"
import img30 from "../../files/img30.jpeg"
import img31 from "../../files/img32.jpeg"
import img32 from "../../files/img32.jpeg"
import img33 from "../../files/img33.jpeg"
import img34 from "../../files/img34.jpeg"
import img35 from "../../files/img35.jpeg"
import img36 from "../../files/img36.jpeg"
import img37 from "../../files/img37.jpeg"
import img38 from "../../files/img38.jpeg"
import img39 from "../../files/img39.jpeg"



function Gallery() {

  useEffect(() => {
    window.scroll({top: 0, left: 0, behavior: 'smooth'})
  }, [])

  return (
    <div className='gallery'>
      <Header mainlabel="Apartmani Jasminka — galerija" mainsrc={img365}/>   

      <div className='pictures'>
        <img src={img1} alt="Apartmani Jasminka - prikaz interijera i eksterijera na odabranim fotografijama s Booking.com" draggable="false" />
        <img src={img2} alt="Apartmani Jasminka - prikaz interijera i eksterijera na odabranim fotografijama s Booking.com" draggable="false" />
        <img src={img3} alt="Apartmani Jasminka - prikaz interijera i eksterijera na odabranim fotografijama s Booking.com" draggable="false" />
        <img src={img4} alt="Apartmani Jasminka - prikaz interijera i eksterijera na odabranim fotografijama s Booking.com" draggable="false" />
        <img src={img5} alt="Apartmani Jasminka - prikaz interijera i eksterijera na odabranim fotografijama s Booking.com" draggable="false" />
        <img src={img6} alt="Apartmani Jasminka - prikaz interijera i eksterijera na odabranim fotografijama s Booking.com" draggable="false" />
        <img src={img7} alt="Apartmani Jasminka - prikaz interijera i eksterijera na odabranim fotografijama s Booking.com" draggable="false" />
        <img src={img8} alt="Apartmani Jasminka - prikaz interijera i eksterijera na odabranim fotografijama s Booking.com" draggable="false" />
        <img src={img9} alt="Apartmani Jasminka - prikaz interijera i eksterijera na odabranim fotografijama s Booking.com" draggable="false" />
        <img src={img10} alt="Apartmani Jasminka - prikaz interijera i eksterijera na odabranim fotografijama s Booking.com" draggable="false" />
        <img src={img11} alt="Apartmani Jasminka - prikaz interijera i eksterijera na odabranim fotografijama s Booking.com" draggable="false" />
        <img src={img12} alt="Apartmani Jasminka - prikaz interijera i eksterijera na odabranim fotografijama s Booking.com" draggable="false" />
        <img src={img13} alt="Apartmani Jasminka - prikaz interijera i eksterijera na odabranim fotografijama s Booking.com" draggable="false" />
        <img src={img14} alt="Apartmani Jasminka - prikaz interijera i eksterijera na odabranim fotografijama s Booking.com" draggable="false" />
        <img src={img15} alt="Apartmani Jasminka - prikaz interijera i eksterijera na odabranim fotografijama s Booking.com" draggable="false" />
        <img src={img16} alt="Apartmani Jasminka - prikaz interijera i eksterijera na odabranim fotografijama s Booking.com" draggable="false" />
        <img src={img17} alt="Apartmani Jasminka - prikaz interijera i eksterijera na odabranim fotografijama s Booking.com" draggable="false" />
        <img src={img18} alt="Apartmani Jasminka - prikaz interijera i eksterijera na odabranim fotografijama s Booking.com" draggable="false" />
        <img src={img19} alt="Apartmani Jasminka - prikaz interijera i eksterijera na odabranim fotografijama s Booking.com" draggable="false" />
        <img src={img20} alt="Apartmani Jasminka - prikaz interijera i eksterijera na odabranim fotografijama s Booking.com" draggable="false" />
        <img src={img21} alt="Apartmani Jasminka - prikaz interijera i eksterijera na odabranim fotografijama s Booking.com" draggable="false" />
        <img src={img22} alt="Apartmani Jasminka - prikaz interijera i eksterijera na odabranim fotografijama s Booking.com" draggable="false" />
        <img src={img23} alt="Apartmani Jasminka - prikaz interijera i eksterijera na odabranim fotografijama s Booking.com" draggable="false" />
        <img src={img24} alt="Apartmani Jasminka - prikaz interijera i eksterijera na odabranim fotografijama s Booking.com" draggable="false" />
        <img src={img25} alt="Apartmani Jasminka - prikaz interijera i eksterijera na odabranim fotografijama s Booking.com" draggable="false" />
        <img src={img26} alt="Apartmani Jasminka - prikaz interijera i eksterijera na odabranim fotografijama s Booking.com" draggable="false" />
        <img src={img27} alt="Apartmani Jasminka - prikaz interijera i eksterijera na odabranim fotografijama s Booking.com" draggable="false" />
        <img src={img28} alt="Apartmani Jasminka - prikaz interijera i eksterijera na odabranim fotografijama s Booking.com" draggable="false" />
        <img src={img29} alt="Apartmani Jasminka - prikaz interijera i eksterijera na odabranim fotografijama s Booking.com" draggable="false" />
        <img src={img30} alt="Apartmani Jasminka - prikaz interijera i eksterijera na odabranim fotografijama s Booking.com" draggable="false" />
        <img src={img31} alt="Apartmani Jasminka - prikaz interijera i eksterijera na odabranim fotografijama s Booking.com" draggable="false" />
        <img src={img32} alt="Apartmani Jasminka - prikaz interijera i eksterijera na odabranim fotografijama s Booking.com" draggable="false" />
        <img src={img33} alt="Apartmani Jasminka - prikaz interijera i eksterijera na odabranim fotografijama s Booking.com" draggable="false" />
        <img src={img34} alt="Apartmani Jasminka - prikaz interijera i eksterijera na odabranim fotografijama s Booking.com" draggable="false" />
        <img src={img35} alt="Apartmani Jasminka - prikaz interijera i eksterijera na odabranim fotografijama s Booking.com" draggable="false" />
        <img src={img36} alt="Apartmani Jasminka - prikaz interijera i eksterijera na odabranim fotografijama s Booking.com" draggable="false" />
        <img src={img37} alt="Apartmani Jasminka - prikaz interijera i eksterijera na odabranim fotografijama s Booking.com" draggable="false" />
        <img src={img38} alt="Apartmani Jasminka - prikaz interijera i eksterijera na odabranim fotografijama s Booking.com" draggable="false" />
        <img src={img39} alt="Apartmani Jasminka - prikaz interijera i eksterijera na odabranim fotografijama s Booking.com" draggable="false" />

      </div>

      <Footer />
    </div>
  )
}

export default Gallery