import React from 'react'
import "./jasminka.css"

import jasminka from "../../files/jasminka.webp"
import solin from "../../files/solin-church.png"

function Jasminka() {
  return (
    <div className='jasminka'>
      <div className='jasm1'>
      <div className='jasminka-info'>
        <img src={jasminka} alt="Jasminka, vlasnica istoimenih apartmana u Solinu u Dalmaciji" draggable="false" />
        <h2>Domaćin: Jasminka</h2>
        <h3>Prosječna ocjena domaćina: 9.2</h3>
      </div>
      <div className='jasminka-desc'>
        <p>Apartmani se nalaze u centru Solina u mirnom dijelu, svi apartmani su kompletno opremljeni i jako je lijepo mjesto za boravak i uživanje... Želim da se moji gosti osjećaju dobrodošli i želim da uživaju tijekom boravka...</p> <br />
        <p>Dolazim iz Solina, dio je Splita, prekrasni mali grad okružen rijekom Jadrom, a blizu je Jadransko more. Volim putovati i smatram da su putovanja najbolje iskustvo koje čovjek može doživjeti.</p> <br />
        <p>100 m dalje je otok Gospe, najstarije marijansko svetište u Hrvatskoj. Salona je antički grad i 1.5 km je od apartmana raznolik sadržaj: teniski teren, streljački klub i nogometni klub (500 m). Restoran je 200 m udaljen, shopping centar 500 m, a manje od 2 km je Dioklecijanova palača. 5 km do centra Splita su plaže. Grad Trogir, pod zaštitom UNESCO-a, također je blizu, kao i 20 km udaljena zračna luka. Jednodnevni izleti po Nacionalnom parku Krka udaljenom 86 km su također mogući. Otoci: Brač 30 minuta vožnje brodom, Hvar 45 min, Vranjic 1.5 km s najvećim morskim akvarijem s preko 130 vrsta riba.</p> <br />
        <p>Osoblje govori: njemački, engleski, hrvatski, talijanski, srpski</p>
      </div>
      </div>
      <img src={solin} alt="Crkva u Solinu nedaleko Apartmana Jasminka kao nacionalno i vjersko blago ovog dalmatinskog kraja" draggable="false" className='solin-img' />
      <p className='solin-txt'><i>Solin, crkva</i></p>
    </div>
  )
}

export default Jasminka