import React, {useEffect} from 'react'
import "./houserules.css"

import Header from "../aheader/Header"
import Footer from "../afooter/Footer"

import img35 from "../../files/img19.jpeg"


function HouseRules() {

  useEffect(() => {
    window.scroll({top: 0, left: 0, behavior: 'smooth'})
  }, [])

  return (
    <div className='hr'>
      <Header mainlabel="Apartmani Jasminka — kućni red" mainsrc={img35}/>

<div className='org'>  
      <h2>Prijava</h2>
      <p>14:00 - 00:00</p>

      <h2>Odjava</h2>
      <p>00:00 - 10:00</p>

      <h2>Otkazivanje i/ili plaćanje unaprijed</h2>
      <p>Pravila otkazivanja i plaćanja unaprijed ovise o vrsti apartmana. Molimo vas da unesete datume boravka i provjerite pravila vezana uz sobu koju ste odabrali.</p>

      <h2>Povratni polog za slučaj štete</h2>
      <p>Polog za slučaj štete u iznosu od EUR 200 potrebno je uplatiti prilikom dolaska. To je oko 1515.17HRK. Ova će se naknada naplatiti u gotovini. Polog će vam biti vraćen prilikom odjave. Puni iznos vašeg pologa bit će u gotovini vraćen nakon pregleda smještajnog objekta.</p>

      <h2>Dobna granica</h2>
      <p>Za prijavu nema dobne granice</p>

      <h2>Odvija li se naplata isključivo preko Booking.com?</h2>
      <p>Ovaj boravak naplaćuje Booking.com u ime objekta, no pobrinite se da prilikom dolaska u objekt imate gotovine za eventualne dodatne troškove.</p>

      <h2>Kućni ljubimci</h2>
      <p>Boravak kućnih ljubimaca nije dozvoljen.</p>

      <h2>Pravila za boravak djece</h2>
      <p>U objektu mogu boraviti djeca svih dobi. Za prikaz točnih cijena i informacija o kapacitetu dodajte broj djece koja putuju s vama i njihovu dob u svoju pretragu u Booking.com. Krevetić za djecu do jedne godine starosti besplatan je na zahtjev. </p><br />
      <p>Dodatni se troškovi ne pridodaju automatski ukupnom iznosu rezervacije i plaćaju se zasebno prilikom boravka. U ovom objektu nisu dostupni pomoćni ležajevi. Najveći dozvoljeni broj dječjih kreveta ovisi o odabranoj jedinici. Molimo, provjerite kapacitet odabrane jedinice. Svi dječji kreveti i pomoćni ležajevi ovise o raspoloživosti.</p>
      </div>

      

      <Footer />
    </div>
  )
}    

export default HouseRules