import './App.css';
import {BrowserRouter, Routes, Route} from "react-router-dom"
import CookieConsent from "react-cookie-consent";


import Home from "./components/home/Home"
import Content from "./components/content/Content"
import Gallery from "./components/gallery/Gallery"
import HouseRules from "./components/houserules/HouseRules"
import ContactPrices from "./components/contactprices/ContactPrices"
import Error from "./components/error/Error"


function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path='/' exact element={<Home />} />
          <Route path='/sadrzaj' exact element={<Content />} />
          <Route path='/galerija' exact element={<Gallery />} />
          <Route path='/kucni-red' exact element={<HouseRules />} />
          <Route path='/kontakt-i-cijene' exact element={<ContactPrices />} />
          <Route path='/*' exact element={<Error />} />
        </Routes>


        <CookieConsent
  location="bottom"
  buttonText="U redu"
  cookieName="myAwesomeCookieName2"
  style={{ background: "#4775bf", border: "rgb(255, 109, 109) solid 10px", paddingBlock: "60px" }}
  buttonStyle={{ color: "#4e503b", fontSize: "13px", borderRadius: "20px" }}
  expires={150}
  
>
  Ova stranica, kao i sve druge, koristi kolačiće. To su malene datoteke podataka, a njihova je namjera da vam osigura poboljšano korisničko iskustvo pri sljedećim posjetima našoj stranici. Nastavite neometano surfati.{" "}
</CookieConsent>


      </BrowserRouter>
    </div>
  );
}

export default App;
