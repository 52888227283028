import React, {useState} from 'react'
import "./header.css"

import { Link } from 'react-router-dom'
import ExpandIcon from '@mui/icons-material/Expand';

import logo from "../../files/logojasminka.png"
import icon1 from "../../files/homeimg.png"
import icon2 from "../../files/applianceimg.png"
import icon3 from "../../files/townimg.svg"
import icon4 from "../../files/flowerimg.png"
import icon5 from "../../files/diningimg.png"


function Header(props) {
  const [toggle, setToggle] = useState(false);

  return (
    <div className='header'>
      <div className='track1'>
        <Link to="/" className='track-logo'>
          <img src={logo} alt="Logo Apartmana Jasminka iz Solina u Dalmaciji" draggable="false" className='track-img' />
        </Link>
        <a href='https://www.booking.com/hotel/hr/apartman-jasminka.hr.html?account_created=1&activeTab=main#availability' target="_blank" rel="noreferrer" className='track-link'>Booking.com</a>
        <Link to="/sadrzaj" className='track-link'>SADRŽAJ</Link>
        <Link to="/galerija" className='track-link'>GALERIJA</Link>
        <Link to="/kucni-red" className='track-link'>KUĆNI RED</Link>
        <Link to="/kontakt-i-cijene" className='differ'>KONTAKT I CIJENE</Link>
      </div>

      <div className='track-small'>
      <Link to="/" className='track-logo'>
          <img src={logo} alt="Logo Apartmana Jasminka iz Solina u Dalmaciji" draggable="false" className='track-img-2' />
        </Link>
        <ExpandIcon className={toggle ? "rotation" : "expand"} onClick={() => {setToggle(!toggle)}}/>
      </div>

      <div className={toggle ? "spreaded" : "hidden"}>
         <a href='https://www.booking.com/hotel/hr/apartman-jasminka.hr.html?account_created=1&activeTab=main#availability' target="_blank" rel="noreferrer" className='track-link-2'>Booking.com</a>
         <Link to="/" className='track-link-2'>POČETNA</Link>
        <Link to="/sadrzaj" className='track-link-2'>SADRŽAJ</Link>
        <Link to="/galerija" className='track-link-2'>GALERIJA</Link>
        <Link to="/kucni-red" className='track-link-2'>KUĆNI RED</Link>
        <Link to="/kontakt-i-cijene" className='differ-2'>KONTAKT I CIJENE</Link>
      </div>

      <div className='track2'>
        <h1>{props.mainlabel}</h1>
      </div>

      <div className='main-img'>
          <img src={props.mainsrc} alt="Apartmani Jasminka u Dalmaciji u Hrvatskoj, primjer interijera i eksterijera samog apartmana s klimom, tuš-kabinom, bazenom, prekrasnom prirodom i isječaka iz grada Solina krajem Splita" draggable="false"  />
      </div>


      <div className='boxes'>
        <div className='box'>
          <img src={icon1} alt="Apartmani Jasminka - prikaz ponude i opis sadržaja" draggable="false" />
          <p>apartmani</p>
        </div>
        <div className='box'>
          <img src={icon2} alt="Apartmani Jasminka - kuhinja" draggable="false" />
          <p>kuhinja</p>
        </div>
        <div className='box'>
          <img src={icon3} alt="Apartmani Jasminka - pogled na grad Solin i more" draggable="false" />
          <p>pogled na grad</p>
        </div>
        <div className='box'>
          <img src={icon4} alt="Apartmani Jasminka - vrt, kamen i krš" draggable="false" />
          <p>vrt</p>
        </div>
        <div className='box'>
          <img src={icon5} alt="Apartmani Jasminka - oprema za roštilj dalmatinskih jela i tradicionalne kuhinje" draggable="false" />
          <p>oprema za roštilj</p>
        </div>
      </div>






    </div>
  )
}

export default Header   