import React, {useEffect} from 'react'
import "./contactprices.css"

import Header from "../aheader/Header"
import Footer from "../afooter/Footer"

import img35 from "../../files/img25.jpeg"
import image1 from "../../files/apart1.jpeg"
import image2 from "../../files/apart2.jpeg"


function ContactPrices() {

  useEffect(() => {
    window.scroll({top: 0, left: 0, behavior: 'smooth'})
  }, [])

  return (
    <div className='cp'>
      <Header mainlabel="Apartmani Jasminka — kontakt i cijene" mainsrc={img35}/>

      <div className='cp-inner'>



<div className='apartments'>  
        <h2>Dvoetažni apartman s 2 spavaće sobe s balkonom</h2>
        <h3>Veličina: 85 m<sup>2</sup></h3>
        <img src={image1} alt="Apartmani Jasminka iz Solina, Dalmacija, mogućnost rezervacije na Booking.com" className='imgset' draggable="false" />
        <p>Ovaj klimatizirani apartman uključuje balkon, TV ravnog ekrana s kabelskim programima i prostor za sjedenje s kaučom. Također obuhvaća potpuno opremljenu kuhinju s pećnicom, hladnjakom, perilicom posuđa i blagovaonicom. Gostima je na raspolaganju vlastita kupaonica s tušem, perilicom rublja, sušilom za kosu i besplatnim kozmetičkim priborom.</p>
        <a href='https://www.booking.com/hotel/hr/apartman-jasminka.hr.html?account_created=1&activeTab=main#room_100563801' target="_blank" rel="noreferrer" className='book'>Provjeri cijenu na Bookingu</a>



        <h2>Apartman s 2 spavaće sobe i terasom</h2>
        <h3>Veličina: 82 m<sup>2</sup></h3>
          <img src={image2} alt="Apartmani Jasminka iz Solina, Dalmacija, mogućnost rezervacije na Booking.com" className='imgset' draggable="false" />
        
        <p>Ovaj klimatizirani apartman uključuje namještenu terasu, TV ravnog ekrana s kabelskim programima i prostor za sjedenje s kaučom. Također obuhvaća potpuno opremljenu kuhinju s pećnicom, hladnjakom, perilicom posuđa i blagovaonicom. Gostima je na raspolaganju vlastita kupaonica s tušem, perilicom rublja, sušilom za kosu i besplatnim kozmetičkim priborom.</p>
        <a href='https://www.booking.com/hotel/hr/apartman-jasminka.hr.html?account_created=1&activeTab=main#room_100563802' target="_blank" rel="noreferrer" className='book'>Provjeri cijenu na Bookingu</a>
        
        </div>

        <a href="https://www.booking.com/hotel/hr/apartman-jasminka.hr.html?account_created=1&activeTab=main#map_closed" target="_blank" rel="noreferrer" className='links2'>PORUKA (Booking.com)</a>
        <a href="mailto:petric206@gmail.com" target="_blank" rel="noreferrer" className='links2'>E-MAIL (petric206@gmail.com)</a>
        <a href="tel:+38598714051" target="_blank" rel="noreferrer" className='links2'>POZIV (+385 98 714 051)</a>
        <a href="https://api.whatsapp.com/send?phone=38598714051" target="_blank" rel="noreferrer" className='links2'>WHATSAPP (+385 98 714 051)</a>
        

      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d8206.802417041825!2d16.482795187700656!3d43.530489566505196!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x13355fab2c6fe531%3A0x2a6393c33ff14726!2sVladimira%20%C4%8Cerine%209%2C%2021210%2C%20Solin!5e0!3m2!1sen!2shr!4v1648670624902!5m2!1sen!2shr" width="100%" height="450" className='googlemap' allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
      </div>
 
      <Footer />
    </div>
  )
}

export default ContactPrices

