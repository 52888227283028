import React, {useEffect} from 'react'
import "./home.css"

import Header from "../aheader/Header"
import Footer from "../afooter/Footer"
import Jasminka from "../ajasminka/Jasminka"
import ApartmentInfo from "../apartmentinfo/ApartmentInfo"
import Reviews from "../areviews/Reviews"

import img35 from "../../files/img15.jpeg"

function Home() {
  
  useEffect(() => {
    window.scroll({top: 0, left: 0, behavior: 'smooth'})
  }, [])

  return (
    <div className='home'>
      <Header mainlabel="Apartmani Jasminka — početna" mainsrc={img35}/>

      <Jasminka />
      <ApartmentInfo />
      <Reviews />

      <Footer />
    </div>
  )
}

export default Home