import React from 'react'
import "./error.css"

import Header from "../aheader/Header"
import Footer from "../afooter/Footer"

import img35 from "../../files/img38.jpeg"
import { Link } from 'react-router-dom'


function Error() {
  return (
    <div className='error'>
      <Header mainlabel="Ovdje nema ničega :(" mainsrc={img35}/>
      <div className='wrong'>Error 404 — cannot find what was requested</div>
      <div className='correct'>Čini se da ste u tražilicu utipkali krivi unos i došli na mjesto gdje ne postoji neki konkretan sadržaj. Provjerite ispravnost poveznice u vašem pretraživaču ili kliknite <Link to="/" className='cr-link'>ovdje</Link> da biste se vratili na početnu stranicu.</div>
      <Footer />
    </div>
  )
}

export default Error