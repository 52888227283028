import React, {useEffect} from 'react'
import "./content.css"

import Header from "../aheader/Header"
import Footer from "../afooter/Footer"

import img35 from "../../files/img16.jpeg"



import BathtubIcon from '@mui/icons-material/Bathtub';
import AirlineSeatIndividualSuiteIcon from '@mui/icons-material/AirlineSeatIndividualSuite';
import YardIcon from '@mui/icons-material/Yard';
import KitchenIcon from '@mui/icons-material/Kitchen';
import BedIcon from '@mui/icons-material/Bed';
import ParkIcon from '@mui/icons-material/Park';
import RowingIcon from '@mui/icons-material/Rowing';
import ChairIcon from '@mui/icons-material/Chair';
import LiveTvIcon from '@mui/icons-material/LiveTv';
import NetworkCheckIcon from '@mui/icons-material/NetworkCheck';
import LocalParkingIcon from '@mui/icons-material/LocalParking';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import KeyboardIcon from '@mui/icons-material/Keyboard';
import CelebrationIcon from '@mui/icons-material/Celebration';
import LocalPoliceIcon from '@mui/icons-material/LocalPolice';
import ApartmentIcon from '@mui/icons-material/Apartment';
import SpaIcon from '@mui/icons-material/Spa';
import BlurOnIcon from '@mui/icons-material/BlurOn';
import TranslateIcon from '@mui/icons-material/Translate';
import EmojiFoodBeverageIcon from '@mui/icons-material/EmojiFoodBeverage';



function Content() {

  useEffect(() => {
    window.scroll({top: 0, left: 0, behavior: 'smooth'})
  }, [])

  return (
    <div className='content'>
       <Header mainlabel="Apartmani Jasminka — sadržaj" mainsrc={img35}/>




       <div className='ai'>
      <h1>Sadržaji u smještajnom objektu Apartments Jasminka</h1>
      <div className='ai-box'>
        <h2>Kupaonica</h2>
        <ul>
          <li><BathtubIcon className='graas'/> totaletni papir</li>
          <li><BathtubIcon className='graas'/> ručnici</li>
          <li><BathtubIcon className='graas'/> dodatna kupaonica</li>
          <li><BathtubIcon className='graas'/> dodatni WC</li>
          <li><BathtubIcon className='graas'/> kada ili tuš-kabina</li>
          <li><BathtubIcon className='graas'/> papuče</li>
          <li><BathtubIcon className='graas'/> vlastita kupaonica</li>
          <li><BathtubIcon className='graas'/> WC</li>
          <li><BathtubIcon className='graas'/> besplatan toaletni pribor</li>

          <li><BathtubIcon className='graas'/> sušilo za kosu</li>

          <li><BathtubIcon className='graas'/> tuš-kabina</li>

       </ul>
      </div>

      <div className='ai-box'>
        <h2>Spavaća soba</h2>
        <ul>
          <li><AirlineSeatIndividualSuiteIcon className='graas'/> posteljina</li>
          <li><AirlineSeatIndividualSuiteIcon className='graas'/> garderoba ili ormar</li>
       </ul>
      </div>

      <div className='ai-box'>
        <h2>Dvorište</h2>
        <ul>
          <li><YardIcon className='graas'/> vrtni namještaj</li>
          <li><YardIcon className='graas'/> vanjski namještaj</li>
          <li><YardIcon className='graas'/> oprema za roštilj</li>
          <li><YardIcon className='graas'/> popločani dio dvorišta</li>
          <li><YardIcon className='graas'/> terasa</li>
          <li><YardIcon className='graas'/> vrt</li>
       </ul>
      </div>


      <div className='ai-box'>
        <h2>Kuhinja</h2>
        <ul>
          <li><KitchenIcon className='graas'/> kuhinjski stol</li>
          <li><KitchenIcon className='graas'/> sredstva za čišćenje</li>
          <li><KitchenIcon className='graas'/> toster</li>
          <li><KitchenIcon className='graas'/> ploče za kuhanje</li>
          <li><KitchenIcon className='graas'/> pećnica</li>
          <li><KitchenIcon className='graas'/> kuhinjski pribor</li>
          <li><KitchenIcon className='graas'/> kuhalo za vodu</li>
          <li><KitchenIcon className='graas'/> kuhinja</li>
          <li><KitchenIcon className='graas'/> perilica rublja</li>
          <li><KitchenIcon className='graas'/> perilica posuđa</li>
          <li><KitchenIcon className='graas'/> mikrovalna pećnica</li>
          <li><KitchenIcon className='graas'/> hladnjak</li>
       </ul>
      </div>


      <div className='ai-box'>
        <h2>Sadržaji sobe</h2>
        <ul>
          <li><BedIcon className='graas'/> utičnica pokraj kreveta</li>
          <li><BedIcon className='graas'/> kauč na rasklapanje</li>
          <li><BedIcon className='graas'/> sušilo za rublje</li>
          <li><BedIcon className='graas'/> krevet na rasklapanje</li>
          <li><BedIcon className='graas'/> stalak za odjeću</li>
          <li><BedIcon className='graas'/> antialergijska soba</li>
          <li><BedIcon className='graas'/> mreža protiv komaraca</li>
          <li><BedIcon className='graas'/> drveni ili parketni pod</li>
          <li><BedIcon className='graas'/> popločani ili mramorni pod</li>
          <li><BedIcon className='graas'/> zvučna izolacija</li>
          <li><BedIcon className='graas'/> tepih</li>
          <li><BedIcon className='graas'/> glačalo i stol za glačanje</li>
          <li><BedIcon className='graas'/> glačalo</li>
       </ul>
      </div>


      <div className='ai-box'>
        <h2>Dvorište i pogled</h2>
        <ul>
          <li><ParkIcon className='graas'/> pogled na unutarnje dvorište</li>
          <li><ParkIcon className='graas'/> pogled na znamenitost</li>
          <li><ParkIcon className='graas'/> pogled na planinu</li>
          <li><ParkIcon className='graas'/> pogled na bazen</li>
          <li><ParkIcon className='graas'/> pogled na vrt</li>
       </ul>
      </div>


      <div className='ai-box'>
        <h2>Aktivnosti</h2>
        <ul>
          <li><RowingIcon className='graas'/> plaža</li>
          <li><RowingIcon className='graas'/> jahanje (*)</li>
          <li><RowingIcon className='graas'/> ronjenje (*)</li>
          <li><RowingIcon className='graas'/> biciklizam</li>
          <li><RowingIcon className='graas'/> planinarenje</li>
          <li><RowingIcon className='graas'/> vožnja kanuom (*)</li>
          <li><RowingIcon className='graas'/> pecanje</li>
          <li><RowingIcon className='graas'/> teren za golf udaljen oko 3 km (*)</li>
          <li><RowingIcon className='graas'/> teniski teren</li>
       </ul>
      </div>


      <div className='ai-box'>
        <h2>Dnevni boravak</h2>
        <ul>
          <li><ChairIcon className='graas'/> blagovaonica</li>
          <li><ChairIcon className='graas'/> kauč</li>
          <li><ChairIcon className='graas'/> prostor za sjedenje</li>
          <li><ChairIcon className='graas'/> radni stol</li>
       </ul>
      </div>



      <div className='ai-box'>
        <h2>Mediji i tehnologija</h2>
        <ul>
          <li><LiveTvIcon className='graas'/> TV ravnog ekrana</li>
          <li><LiveTvIcon className='graas'/> sef za prijenosno računalo</li>
          <li><LiveTvIcon className='graas'/> kabelski programi</li>
          <li><LiveTvIcon className='graas'/> radio</li>
          <li><LiveTvIcon className='graas'/> TV</li>
          <li><LiveTvIcon className='graas'/> pay-per-view programi</li>
       </ul>
      </div>



      <div className='ai-box'>
        <h2>Hrana i piće</h2>
        <ul>
          <li><EmojiFoodBeverageIcon className='graas'/> voće (*)</li>
          <li><EmojiFoodBeverageIcon className='graas'/> vino ili šampanjac (*)</li>
          <li><EmojiFoodBeverageIcon className='graas'/> dostava namirnica</li>
          <li><EmojiFoodBeverageIcon className='graas'/> aparat za pripremu kave ili čaja</li>
       </ul>
      </div>



      <div className='ai-box'>
        <h2>Internet</h2>
        <ul>
          <li><NetworkCheckIcon className='graas'/> Bežični pristup internetu dostupan je u cijelom objektu i ne naplaćuje se.</li>
       </ul>
      </div>


<div className='ai-box'>
      <h2>Parkiralište</h2>
        <ul>
          <li><LocalParkingIcon className='graas'/>Besplatno privatno parkiralište dostupno je u okviru objekta i nije potrebna rezervacija. Parking je uz cestu.</li>
       </ul>
      </div>


      <div className='ai-box'>
      <h2>Prijevoz</h2>
        <ul>
          <li><DirectionsCarIcon className='graas'/>usluga prijevoza moguća (*)</li>
       </ul>
      </div>


      <div className='ai-box'>
      <h2>Usluge recepcije</h2>
        <ul>
          <li><KeyboardIcon className='graas'/> izdavanje računa</li>
          <li><KeyboardIcon className='graas'/> privatna prijava i odjava</li>
          <li><KeyboardIcon className='graas'/> brza prijava i odjava</li>
       </ul>
      </div>



      <div className='ai-box'>
      <h2>Zabava i obiteljski sadržaji</h2>
        <ul>
          <li><CelebrationIcon className='graas'/> dječja kolica</li>
          <li><CelebrationIcon className='graas'/> sadržaj za dječju igru na otvorenom</li>
          <li><CelebrationIcon className='graas'/> društvene igre</li>
          <li><CelebrationIcon className='graas'/> puzle</li>
       </ul>
      </div>



      <div className='ai-box'>
      <h2>Sigurnost i zaštita</h2>
        <ul>
          <li><LocalPoliceIcon className='graas'/> protupožarni aparati</li>
          <li><LocalPoliceIcon className='graas'/> sef</li>
       </ul>
      </div>




      <div className='ai-box'>
      <h2>Karakteristike zgrade</h2>
        <ul>
          <li><ApartmentIcon className='graas'/> privatni apartman u zgradi</li>
       </ul>
      </div>




      <div className='ai-box'>
      <h2>Wellness-sadržaji</h2>
        <ul>
          <li><SpaIcon className='graas'/> masažna stolica</li>
          <li><SpaIcon className='graas'/> suncobrani</li>
          <li><SpaIcon className='graas'/> ručnici za bazen i plažu</li>
       </ul>
      </div>


      <div className='ai-box'>
        <h2>Razno</h2>
        <ul>
          <li><BlurOnIcon className='graas'/> prostor za pušače</li>
          <li><BlurOnIcon className='graas'/> klima-uređaj</li>
          <li><BlurOnIcon className='graas'/> sobe s antialergijskom posteljinom</li>
          <li><BlurOnIcon className='graas'/> grijanje</li>
          <li><BlurOnIcon className='graas'/> obiteljske sobe</li>
          <li><BlurOnIcon className='graas'/> sobe za nepušače</li>
          <li><BlurOnIcon className='graas'/> <i style={{color: "orange"}}>napomena: (*) uz neke od sadržaja navedenih iznad označava sadržaj uz dodatnu, posebnu nadoplatu</i></li>

       </ul>
      </div>


      <div className='ai-box'>
        <h2>Usluga dostupna na jezicima:</h2>
        <ul>
          <li><TranslateIcon className='graas'/> njemački</li>
          <li><TranslateIcon className='graas'/> engleski</li>
          <li><TranslateIcon className='graas'/> hrvatski</li>
          <li><TranslateIcon className='graas'/> talijanski</li>
          <li><TranslateIcon className='graas'/> srpski</li>
       </ul>
      </div>





    </div>


 
        <Footer />
    </div>
  )
}

export default Content